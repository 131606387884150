<template>
  <div>
    <v-simple-table>
      <template v-slot:default v-if="items.length">
        <thead>
          <tr>
            <th>Indicador</th>
            <th class="text-center">Tipo Dado</th>
            <th class="text-center">Quantidade</th>
            <th class="text-center">Valor</th>
            <th class="text-center" v-can-access="24">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id_realizado_individual">
            <td>{{ `${item.id_indicador} - ${item.indicador}` }}</td>
            <td class="text-center">{{ item.tipo_dado }}</td>
            <td class="text-center">{{ item.qtde }}</td>
            <td class="text-center">
              {{ item.valor | BrazilianCurrency }}
            </td>
            <td class="text-center" v-can-access="24">
              <v-btn
                icon
                @click="deleteItem(item)"
                class="ml-2"
                color="red"
                dark
                small
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import comissao from "@/services/http/comissaoService";
import currencyMixin from "@/mixins/currencyMixin";
export default {
  props: {
    items: {
      type: Array
    }
  },

  mixins: [currencyMixin],

  data() {
    return {
      dialog: false,
      labelBtn: "Salvar",
      meta: {}
    };
  },

  methods: {
    async deleteParametrosData(id) {
      try {
        await this.$swal.confirm(
          "Deseja deletar o parâmetro?",
          "Essa ação não pode ser desfeita"
        );
        await comissao()
          .realizadoIndividual(id)
          .delete(null, { notification: true });
        await this.fetchRealizadoIndividual();
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style></style>
